<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="">
		<div>
			<!-- <a-row :gutter="24">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 mt-10">
					<h5>Classroom</h5>
				</a-col>
			</a-row> -->
			
			<div v-if="!loadingData == message.length == 0">

				<a-row :gutter="24" class="">
                    <!-- style="background-color: #F5F5F5;" -->

					<a-col class="p-0 m-0" style="height: 100%;" :span="24" :md="24" :lg="24" :xl="24">
						<!-- <a-card class=" px-5 h-100"> -->
							<div id="chatRoom" ref="chatRoom" class="p-20" style="height: 60vh; background-color: #FFFFFF;">
                                <div class="" style="width: 100%;" v-for="(groupedMessages, smsDate) in messages" :key="smsDate">
                                    <a-row :gutter="24">
                                        <a-col :span="3" :xs="6" :sm="8" :md="10" :lg="10"></a-col>
                                        <a-col :span="18" :xs="12" :sm="8" :md="4" :lg="4" class="mt-10 mb-10 text-center">
                                            <h6 class="py-5" style="font-weight: 600; font-size: 12px;  background-color: #DBDFEB; border-radius: 10px;">{{ smsDate }}</h6>
                                        </a-col>
                                        <a-col :span="3" :xs="6" :sm="8" :md="10" :lg="10"></a-col>
                                    </a-row>
                                    <a-comment class="my-20 px-20" v-for="sms in groupedMessages" :key="sms.uuid" :class="userDetails != null && userDetails.uuid == sms.creator.uuid ? 'otherchat' : 'mychat'"  style="" :style="userDetails != null && userDetails.uuid == sms.creator.uuid ? windowWidth > 800 ? 'margin-left: auto; background-color: #D3D0EA; max-width: 45%;' : 'margin-left: auto; background-color: #D3D0EA; max-width: 95%;' : windowWidth > 800 ? 'margin-right: auto; background-color: #FFF; box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);  max-width: 45%' : 'margin-right: auto; background-color: #FFF; box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);  max-width: 95%'">
                                        <template #author><a :style="userDetails != null && userDetails.uuid == sms.creator.uuid ? 'color: #724EBF' : ''"><strong>{{ sms.creator.firstName }} {{ sms.creator.lastName }}</strong></a></template>
                                        <template #avatar>
                                        <a-avatar :src="sms.creator.profile != null ? sms.creator.profile : ''" alt="Han Solo">{{ sms.creator.profile != null ? '' : `${sms.creator.firstName[0]}${sms.creator.lastName[0]}` }}</a-avatar>
                                        </template>
                                        <template #content>
                                            <p v-if="sms.content != null && sms.content.length > 0" :style="userDetails != null && userDetails.uuid == sms.creator.uuid ? 'color: #121417' : ''">{{ sms.content != null && !sms.content.includes('jpg') ? sms.content : '' }}</p>
                                            <img src="../../../../public/images/file.png" width="100px" height="100px" v-if="sms.attachment != null && sms.attachment.path != null && isDocumentFile(sms.attachment.path)" @click="onViewDocument(sms.attachment)" class="mt-10"/>
                                            <img id="classImg" width="100px" height="120px" v-if="sms.attachment != null && sms.attachment.path.includes('jpeg')" alt="" :src="sms.attachment.path"  @click="onViewImage(sms.attachment)" class="mt-10"/>
                                        </template>
                                        <template #datetime>
                                            <span class="" style="" :style="userDetails != null && userDetails.uuid == sms.creator.uuid ? 'color: #594F4F' : 'color: #594F4F'">{{ getFormattedDate(sms.createdAt) }}</span>
                                        </template>
                                    </a-comment>
                                </div>
                            </div>
                    </a-col>

                    <a-col class="mb-24 mt-0 pt-0 px-0" style="height: 100%;" :span="24" :md="24" :lg="24" :xl="24">
                        <a-card style="background-color: transparent; border: 0px; border-radius: 0px;">
                            <a-row :gutte="24" class="pt-5 pb-10" style="">

                                <a-col :span="24">
                                    <!-- <a-button id="textBtn" type="light" shape="circle" class="mx-10" @click="onSelectRecorder">
                                        <svg class="w-6 h-6" style="width: 18px; color: #F9AA33;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd" d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20ZM8 9c0-.6.4-1 1-1a1 1 0 0 1 0 2 1 1 0 0 1-1-1Zm6 0c0-.6.4-1 1-1a1 1 0 1 1 0 2 1 1 0 0 1-1-1Zm-5.5 7.2c-1-.8-1.7-2-1.9-3.2h10.8a5.5 5.5 0 0 1-9 3.2Z" clip-rule="evenodd"/>
                                        </svg>
                                    </a-button> -->
                                    <a-textarea 
                                        v-model="message.content" 
                                        @keyup.enter="handleSubmitMessage(null)" 
                                        placeholder="Message" 
                                        class="px-15 py-10"
                                        :autosize="false"
                                        :rows="windowWidth > 800 ? 1 : 3 "
                                        style="border: none !important; border-radius: 20px;"
                                        :style="windowWidth > 931 ? 'width: 75% !important' : 'width: 100% !important'">
                                        <template #prefix>
                                            <font-awesome-icon icon="fa-regular fa-face-smile" class="mr-5" style="color: #594F4F !important"/>
                                        </template>
                                    </a-textarea>
                                    <a-button type="primary" shape="circle" class="ml-10" @click="onSelectImage" :style="windowWidth > 931 ? '' : 'margin-top: 15px'">
                                        <font-awesome-icon icon="fa-regular fa-image fa-xl" class="ml-5"/>
                                    </a-button>
                                    <a-button type="primary" shape="circle" class="ml-10" @click="onSelectFile"  :style="windowWidth > 931 ? '' : 'margin-top: 15px'">
                                        <font-awesome-icon icon="fa-solid fa-paperclip fa-xl" class="ml-5"/>
                                    </a-button>
                                    <a-button type="primary" shape="circle" class="ml-10" style="" @click="onSelectRecorder"  :style="windowWidth > 931 ? '' : 'margin-top: 15px'">
                                        <font-awesome-icon icon="fa-solid fa-microphone fa-xl" class="ml-5"/>
                                    </a-button>
                                    <a-button :loading="message.loading" type="primary" class="ml-10" @click="handleSubmitMessage(null)" :disabled="message.content == null || message.content.length == 0" style="color: #FFFFFF !important;"  :style="windowWidth > 931 ? '' : 'margin-top: 15px'">
                                        <font-awesome-icon icon="fa-regular fa-paper-plane fa-xl" class="mr-10"/>
                                        Send
                                    </a-button>
                                </a-col>

                                <a-col>
                                    <input 
                                        type="file"
                                        ref="imageInput"
                                        @change="handleImageChange"
                                        hidden/>

                                    <input 
                                        type="file"
                                        ref="fileInput"
                                        @change="handleFileChange"
                                        hidden/>
                                </a-col>
                            </a-row>
						</a-card> 
					</a-col>

				</a-row>


			</div>


			<div v-if="loadingData">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 40vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<a-spin class="text-primary" size="large"/>

					</a-col>

				</a-row>
			</div>

			<div v-if="!loadingData && message.length > 0">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 40vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<label>{{message}}</label>

					</a-col>

				</a-row>
			</div>
		</div>

        <!-- IMAGE MODAL -->
		<a-modal v-model="messageAttachment.showModal" :footer="null">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-weight: normal; font-size: 18px;" class="text-center">Selected {{ messageAttachment.type == 'file' ? 'File' : 'Image'}}</h6>
				</a-col>
			</a-row>

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
                <a-col :span="24" class="text-center">
                    {{ messageAttachment.fileThumbnail }}
                </a-col>
				<a-col :span="24" class="text-center">
                    <img src="../../../../public/images/file.png" width="150px" height="150px" v-if="messageAttachment.type == 'file'"/>
                    <img id="classImg" width="200px" height="200px" :src="messageAttachment.fileUrl" v-if="messageAttachment.type == 'image'" alt="">
                </a-col>
			</a-row>


			<div>
				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="mt-20">
					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
						<a-button :loading="messageAttachment.loading" type="primary" class="ml-10" @click="handleSubmitFile" style="color: #FFFFFF !important;">
                            <!-- <svg class="" style="color: #734EBF !important; transform: rotate(60deg); width: 34px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"/>
                            </svg> -->
                            Upload
                        </a-button>
					</a-col>
				</a-row>
			</div>
		</a-modal>


        <!-- IMAGE MODAL -->
		<!-- <a-modal v-model="messageAttachment.showModal" :footer="null">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-weight: normal; font-size: 18px;" class="text-center">Selected {{ messageAttachment.type == 'file' ? 'File' : 'Image'}}</h6>
				</a-col>
			</a-row>

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
                <a-col :span="24" class="text-center">
                    {{ messageAttachment.fileThumbnail }}
                </a-col>
				<a-col :span="24" class="text-center">
                    <img src="../../../../public/images/file.png" width="150px" height="150px" v-if="messageAttachment.type == 'file'"/>
                    <img id="classImg" width="200px" height="200px" :src="messageAttachment.fileUrl" v-if="messageAttachment.type == 'image'" alt="">
                </a-col>
			</a-row>


            <a-row :gutte="24" class="py-20">

                <a-col :span="24">
                    <a-input v-model="message.content" placeholder="Type your message here" style="width: 65%;"/>
                    <a-button :loading="message.loading || messageAttachment.loading" type="primary" class="ml-10" @click="handleSubmitFile" style="color: #FFFFFF !important;">
                         <svg class="" style="color: #734EBF !important; transform: rotate(60deg); width: 34px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"/>
                        </svg> 
                        Send
                    </a-button>
                </a-col>
            </a-row>


			<div>
				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="mt-20">
					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
						<a-button :loading="messageAttachment.loading" type="primary" class="ml-10" @click="handleSubmitFile" style="color: #FFFFFF !important;">
                            
                            Upload
                        </a-button>
					</a-col>
				</a-row>
			</div> 
		</a-modal> -->


        <img-viewer ref="viewer"/>

	</div>
</template>

<script>
    import dayjs from 'dayjs';
    import { notification } from 'ant-design-vue';
    import ImgViewer from "../Services/ImageViewer.vue";

    function genImages() {
  const sourceImages = [];
  const base = Math.floor(Math.random() * 60, 10) + 10;
  for (let i = 0; i < 1; i++) {
    sourceImages.push({
      thumbnail: `https://d3oh23bkh3m9fb.cloudfront.net/images/473d48a1-7921-45b7-9909-1e1b2a0ad601.jpeg`,
      source: `https://d3oh23bkh3m9fb.cloudfront.net/images/473d48a1-7921-45b7-9909-1e1b2a0ad601.jpeg`
    });
  }
  return sourceImages;
}
    
	export default ({
		
		components: {
            ImgViewer
		},

        props: ['classDetails', 'isCreator', 'userDetails'],
		data() {
			return {
                windowWidth: window.innerWidth,
				loadingData: false,
                messages: [],
				form: this.$form.createForm(this, { name: 'topic_login' }),
                demosms: [],
				message: {
					content: null,
					clazz: this.$route.params.uuid,
					attachment: null,
                    poll: null,
                    thread: null,
					isCompleted: false,
                    loading: false,
					isCreate: true,
					showModal: false,
				},

                messageAttachment: {
                    type: null,
                    file: null,
                    fileUrl: null,
                    fileThumbnail: null,
                    loading: false,
                    showModal: false,

                },

                allImages: [genImages(), genImages(), genImages()]
      		}
		},
		watch: {
            // messages: {
            //     handler() {
            //         // this scrolls the messages to the bottom on loading data
            //         const container = this.$el.querySelector('#chatRoom');
            //         this.$nextTick(() => {
            //         // DOM updated
            //             container.scrollTop = container.scrollHeight;
            //         });
            //     },
            //     deep: true,
            // }, 
        },
        mounted() {
			this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
			})
		},

		beforeDestroy() { 
			window.removeEventListener('resize', this.onResize); 
		},
        
		created() {
			this.getMessages();
            // this.scrollToEnd();
		},

		methods: {

            onResize() {
				this.windowWidth = window.innerWidth
			},

			formatName(name) {
				let formattedName = name.length > 30 ? `${name[0].toUpperCase()}${name.substring(1, 25).toLowerCase()}...` : `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`;
                return formattedName;
            },


            getFormattedDate(datetime) {
                let formattedDate = this.$Moment(datetime).format('hh:mm A');

                return formattedDate;
            },


			async initializeClassDetails() {
				if(this.isCreator != null && this.classDetails != null) {
					await localStorage.setItem('isCreator', this.isCreator);
					await localStorage.setItem('classDetails', JSON.stringify(this.classDetails));
				}
			},

            isDocumentFile(path) {

                let isDocument = false;

				if(path.includes('pdf') || path.includes('pdf')) {
					isDocument = true
				}
				else if(path.includes('.doc') || path.includes('.doc')){
					isDocument = true
				}
                else if((path.includes('.xls') || path.includes('.xls')) || (path.includes('.csv') || path.includes('.csv'))){
					isDocument = true
				}
				else if(path.includes('.mkv') || path.includes('.mp4')){
					isDocument = true
				}
				else{
					isDocument = false
				}

                return isDocument;
            },


            async onViewDocument(attachment) {
				
				if((attachment.path != null && attachment.path.includes('.pdf')) || (attachment.path != null && attachment.path.includes('.pdf'))) {
					await localStorage.setItem("pdfFilePath", attachment.path)
					// await localStorage.setItem("pdfFileName", thumbnail)

					window.open(`/pdf-viewer`, '_blank');
				}
				else if((attachment.path != null && attachment.path.includes('.doc')) || (attachment.path != null && attachment.path.includes('.doc'))) {
					await localStorage.setItem("docFilePath", attachment.path)
					// await localStorage.setItem("docFileName", thumbnail)

					window.open(`/doc-viewer`, '_blank');
				}
                else if((attachment.path != null && attachment.path.includes('.xls')) || (attachment.path != null && attachment.path.includes('.xls')) || (attachment.path != null && attachment.path.includes('.csv')) || (attachment.path != null && attachment.path.includes('.csv'))) {
					await localStorage.setItem("excelFilePath", attachment.path)
					// await localStorage.setItem("docFileName", thumbnail)

					window.open(`/excel-viewer`, '_blank');
				}
				else if((attachment.path != null && attachment.path.includes('.mkv')) || (attachment.path != null && attachment.path.includes('.mp4'))){
					await localStorage.setItem("videoFilePath", attachment.path)
					// await localStorage.setItem("videoFileName", thumbnail)

					window.open(`/video-player`, '_blank');
				}
				else{
					console.log(attachment)

					this.notify('File format currently is not supported', 'error')
				}

				// this.$router.push(`/pdf-viewer`);
			},


            onViewImage(attachment) {
                console.log([{ source: attachment.path, thumbnail: `image`, }]);
                this.$refs.viewer.show([{ source: attachment.path, thumbnail: attachment.path, }]);
            },



			async getMessages() {

				// this.loadingData = true;

                const currentPage = 1; 
				const perPage = 20;

                // isSubtopic=false&

                let url = `${this.$BACKEND_URL}/messages?clazz=${this.$route.params.uuid}&orderBy=createdAt&order=ASC&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.messages = response.data.data;

                        this.demosms = response.data.data
                        this.messages = response.data.data.reduce((sms, currentSms) => {
							const category = this.$Moment(currentSms.createdAt).format('DD MMM YYYY');
							if (!sms[category]) {
								sms[category] = [];
							}
							sms[category].push(currentSms);
							return sms;
						}, {});
                    }
					
					// this.loadingData = false;

                    // this.scrollToEnd();
                    // setInterval(this.scrollToEnd,500);
                    const el = document.getElementById('chatRoom');
                    el.scrollIntoView({behavior: "smooth"});
					
                }).catch(async(error) => {
					
					// this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            scrollToEnd() {    	
                // const height = this.$refs.picture.clientHeight;
                var lastElement = this.$refs.chatRoom?.lastElementChild
                // console.log(lastElement)
                if(lastElement?.lastElementChild != null) {
                    var lastElement1 = lastElement?.lastElementChild
                    lastElement1?.scrollIntoView({
                        behavior: 'smooth',
                    });
                }else{
                    lastElement?.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
                
                // container.scrollTop(container.scrollHeight);
            },

            onSelectFile() {
                this.$refs.fileInput.click();
            },

            handleFileChange(event) {
                this.messageAttachment.type = 'file'
				this.messageAttachment.file = event.target.files[0];
				this.messageAttachment.fileThumbnail = event.target.files[0].name;
                this.messageAttachment.fileUrl = URL.createObjectURL(event.target.files[0]);

                this.messageAttachment.showModal = true
			},

            onSelectImage() {
                this.$refs.imageInput.click();
            },

            handleImageChange(event) {
                this.messageAttachment.type = 'image'
				this.messageAttachment.file = event.target.files[0];
				this.messageAttachment.fileThumbnail = event.target.files[0].name;
                this.messageAttachment.fileUrl = URL.createObjectURL(event.target.files[0]);

                this.messageAttachment.showModal = true
			},


            onSelectRecorder() {
                this.notify('This feature is coming soon!!!', 'success')
            },


            async handleSubmitFile() {
				
				this.messageAttachment.loading = true

				let token = await localStorage.getItem("user_token")

				const config = {
					headers: {
						'Content-Type': 'multipart/form-data',
						'Authorization': `Bearer ${token}`,
					},
				};

				const formData = new FormData(); 

				formData.append(`files`, this.messageAttachment.file, this.messageAttachment.fileThumbnail); 

				let url = `${this.$BACKEND_URL}/uploads`;
				
				this.$AXIOS.post(url, formData, config).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.handleSubmitMessage(response.data.data[0])
					}
				}).catch((err) => {
					console.log(err)
					this.messageAttachment.loading = false;
					
					this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');
					
				});

				
			},


			handleSubmitMessage(attachment) {

                this.message.loading = true;
                this.message.creator = this.userDetails.uuid;

                if(attachment != null) {
                    let { path, mimetype, size } = attachment
                    this.message.content = null;
                    this.message.attachment = { path, mimetype, size };
                }

                let url = `${this.$BACKEND_URL}/messages`;

                this.$AXIOS.post(url, this.message).then(async(response) => {
                    
                    this.message.uuid = null;
					this.message.content = null;
					this.message.attachment = null;
                    this.message.poll = null;
                    this.message.thread = null;
					this.message.isCompleted = false;
					this.message.isCreate = true;
					this.message.showModal = false;

                    this.messageAttachment.loading = false;
                    this.messageAttachment.showModal = false

                    this.getMessages()

                    // this.notify('Message has been send successfully', 'success')

                    this.message.loading = false;

                }).catch(async(error) => {
                    
                    this.message.loading = false;

                    this.messageAttachment.loading = false;
                    this.messageAttachment.showModal = false

                    this.notify(error.response.data.message ?? 'Connection errror', 'error')

                    if(error.response && error.response.status == 401) {
                        await localStorage.setItem("user_token", null);
                        await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });

			},


			async onDelete(uuid) {

				// e.preventDefault();
				
				this.message.loading = true;

				let token = await localStorage.getItem("user_token")

				let url = `${this.$BACKEND_URL}/topics/${uuid}`;

				this.$AXIOS.delete(url).then(async(response) => {
					
					this.notify('Topic was deleted successfully', 'success')

					this.getMessages();
					this.message.loading = false;

				}).catch(async(error) => {
					
					this.message.loading = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style scoped>


#chatRoom {
  flex-grow: 1;
  overflow: auto;
  padding: 1rem;
}

.mychat{
  border-radius : 0px 20px 20px 20px;
}
.otherchat{
  border-radius : 20px 0px 20px 20px;
}
</style>